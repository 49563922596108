import { useMemo } from 'react';
import { CONTENT_IMAGE_RATIO, CONTENT_IMAGE_SIZE, MEDIA_QUERIES } from 'containers/ProductDetailsPage/constants';
import createImageSizesAttr from 'utils/createImageSizesAttr';
import getResponsiveSrc from 'utils/getImageSrcset';
import getHeightFromAspectRatio from 'utils/urls/getHeightFromAspectRatio';

const responsiveContentImages = [CONTENT_IMAGE_SIZE.SMALL, CONTENT_IMAGE_SIZE.MEDIUM];
const mediaQueriesList = [MEDIA_QUERIES.MEDIUM, MEDIA_QUERIES.SMALL];

function setIntrinsicWrapper(item: HTMLImageElement, imageSize: number, imageRatio: string) {
  let boxElement = item.parentElement;
  if (boxElement) {
    boxElement?.classList.add('ratio-box');
  } else {
    boxElement = document.createElement('div');
    boxElement.classList.add('ratio-box');
    item.parentNode?.insertBefore(boxElement, item);
    boxElement.appendChild(item);
  }
  /* padding-bottom is calculated and rendered in to HTML */
  const paddingBottom = `${getHeightFromAspectRatio(imageSize, imageRatio, 'percentage')}%`;
  boxElement.style.setProperty('--box-pb', paddingBottom);
}

const useProductDetailContent = (content?: string | null, productName?: string | null) => {
  const contentDesc = useMemo(() => {
    if (document.body === null) throw new Error('Can only be use in browser environment');
    if (!content) return '';
    const tempWrapperElement = document.createElement('div');
    tempWrapperElement.innerHTML = content;
    const imgTags = tempWrapperElement.querySelectorAll('img');
    imgTags.forEach((item, index) => {
      /* Adding box element with css instrinsic ratio scaling to prevent CLS */
      setIntrinsicWrapper(item, CONTENT_IMAGE_SIZE.LARGE, CONTENT_IMAGE_RATIO);
      /*  Lazy loading for offscreen images */
      item.alt = `${productName}_details_${index + 1}`;
      const srcSet = getResponsiveSrc(item.src, responsiveContentImages, CONTENT_IMAGE_RATIO);
      item.srcset = srcSet;
      item.sizes = createImageSizesAttr(responsiveContentImages, mediaQueriesList);
      item.setAttribute('loading', 'lazy');
      item.style.minHeight = `${getHeightFromAspectRatio(CONTENT_IMAGE_SIZE.SMALL, CONTENT_IMAGE_RATIO)}`;
    });
    const modifiedHtmlString = tempWrapperElement.innerHTML;
    return modifiedHtmlString;
  }, [content, productName]);

  return contentDesc;
};

export default useProductDetailContent;
