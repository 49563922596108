/**
 *
 * ProductDetailsPage
 *
 */

import { Col, Row, Skeleton } from 'antd';
import { Dispatch, compose } from 'redux';
import React, { FC, Fragment, useState, useCallback, useMemo, useRef, useEffect, lazy, Suspense } from 'react';
import {
  selectAddedProducts,
  selectAddingProducts,
  selectStore,
  selectUserPermissions,
  selectBreadCrumbDataMarkup,
} from 'containers/MainLayout/selectors';

import { FormattedMessage } from 'react-intl';
import { Helmet } from 'react-helmet';
import MarketItem from 'components/MarketItem/MarketItem';
import { ContainerState, RootState } from './types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import injectReducer from 'utils/injectReducer';
import injectSaga from 'utils/injectSaga';
import messages from './messages';
import reducer from './reducer';
import saga from './saga';
import selectProductDetailsPage from './selectors';
import { setFavorite, setProductNote } from 'containers/MainLayout/actions';
import styled from 'styled-components';
import translations from 'translations';
import {
  Product as ProductType,
  ProductNoteInput,
  Store,
  TrackingAddToCartAddFromType,
  TrackingProductImpressionType,
} from 'types/schema';
import { getImageByCategory } from 'containers/MarketPage/ImageByCategory';
import renderWithWebDomain from 'utils/renderWithWebDomain';
import generateProductSlug from 'utils/generateProductSlug';
import METADATA from 'containers/MarketPage/metadata';
import formatMoney from '../../utils/formatMoney';
import { DESCRIPTION_BY_LANGUAGE, CUSTOM_DESCRIPTION } from './constants';
import { push } from 'connected-react-router';
import useProductDetailContent from 'components/Image/useProductDetailContent';
import LoadingIndicator from 'components/LoadingIndicator';

interface IAddedProduct {
  [productId: string]: number;
}
interface IAddingProduct {
  [productId: string]: boolean;
}

interface StateProps {
  productdetailspage: ContainerState;
  store: Store;
  addedProducts: IAddedProduct;
  addingProducts: IAddingProduct;
  permissions: string[];
  breadCrumbDataMarkup: string;
}

interface DispatchProps {
  onToggleFavorite: (data: { product: ProductType }) => void;
  setProductNote: (data: ProductNoteInput) => void;
  gotoSignIn: () => void;
}

type Props = StateProps & DispatchProps;

const Header = styled.h2`
  margin-top: 12pt;
  margin-bottom: 12pt;
  font-size: 18px;
  font-weight: 500;
  color: #424242;
`;

const ProductDetailsContainer = styled.div`
  padding: 16px 0px 16px;
  @media (max-width: 767px) {
    padding: 8px 16px 16px;
  }
`;

const ProductDescriptionWrapper = styled.div`
  margin: 24px 0;
  padding-bottom: 24px;
  border-bottom: 1px solid #e8ebf2;
`;

const ProductDescriptionTitle = styled.h1`
  font-size: 18px;
  color: #424242;
  font-weight: 500;
  margin-bottom: 24px;
`;

const ProductDescriptionContent = styled.div`
  font-size: 15px;
  color: rgba(0, 0, 0, 0.87);
  font-weight: 400;
  line-height: 23px;
  max-height: 150px;
  overflow: hidden;
  ${({ isShowFullDescription }) =>
    isShowFullDescription &&
    `
  max-height: none;
  overflow: visible;
`}
  p {
    img {
      max-width: 100%;
    }
  }
`;

const SkeletonWrapper = styled.div`
  min-height: 380px;
  width: 100%;
`;

const ReadMore = styled.div`
  font-size: 14px;
  color: #008fe5;
  cursor: pointer;
  text-align: center;
  margin: 8px 0 0;
  position: relative;
  &:before {
    content: '';
    position: absolute;
    bottom: 100%;
    left: 0px;
    right: 0px;
    height: 60px;
    z-index: 1;
    background: linear-gradient(
      rgba(249, 250, 252, 0) 0%,
      rgba(249, 250, 252, 0.6) 40%,
      rgba(249, 250, 252, 0.95) 85%,
      rgb(249, 250, 252) 100%
    );
    pointer-events: none;
    & {
      ${({ isShowFullDescription }) =>
        isShowFullDescription &&
        `
        display: none;
        `}
    }
  }
`;

const Product = lazy(
  () =>
    import(
      /* webpackPreload: true */
      'components/Product/Product'
    ),
);

const ModalNote = lazy(() => import('components/Checkout/ModalNote'));

const ProductDetailsPage: FC<Props> = (props) => {
  const {
    permissions,
    addingProducts,
    addedProducts,
    productdetailspage: { product: productDetails, relateProducts, noteItemLoading },
    onToggleFavorite,
    setProductNote,
    gotoSignIn,
    breadCrumbDataMarkup,
  } = props;

  const {
    id,
    name,
    nameLocal,
    description,
    imageUrl,
    price,
    category,
    supplierInternalCode,
    vat,
    uomLocal,
    inStock,
    preservation,
    supplier,
    origin,
    details,
    loading,
    validProductUrl,
    alternateProductUrl,
    localLang,
    alternateLang,
  } = productDetails;

  const loggedIn = !!localStorage.getItem('token');
  const [previewProduct, setPreviewProduct] = useState<ProductType>();
  const [isOpenNoteModal, setIsOpenNoteModal] = useState<boolean>(false);
  const [isShowFullDescription, setIsShowFullDescription] = useState(false);
  const productDetailRef = useRef<HTMLElement>(null);
  const onClickOpenNoteModal = useCallback<(product: ProductType) => void>(
    (product) => {
      if (loggedIn) {
        setPreviewProduct(product);
        setIsOpenNoteModal(true);
      } else {
        gotoSignIn();
      }
    },
    [setIsOpenNoteModal, setPreviewProduct],
  );

  const onClickDeleteNote = useCallback(
    (product) => {
      setProductNote({ productId: product.id, note: '' });
    },
    [setProductNote],
  );

  const onClickCloseNoteModal = useCallback(() => {
    setPreviewProduct(undefined);
    setIsOpenNoteModal(false);
  }, [setPreviewProduct, setIsOpenNoteModal]);

  const onSubmitAddingNote = useCallback(
    (note) => {
      if (previewProduct?.id) {
        setProductNote({ productId: previewProduct.id, note: note });
      }
      onClickCloseNoteModal();
    },
    [previewProduct, setProductNote, onClickCloseNoteModal],
  );

  const customDescriptionValue = useMemo(() => {
    if (localLang) {
      const customDescription = {
        [DESCRIPTION_BY_LANGUAGE[localLang].supplied]: supplier?.name,
        [DESCRIPTION_BY_LANGUAGE[localLang].origin]:
          origin === 'Other'
            ? CUSTOM_DESCRIPTION.origin[localLang][0]
            : `${CUSTOM_DESCRIPTION.origin[localLang][1]} ${origin}`,
        [DESCRIPTION_BY_LANGUAGE[localLang].price]: `${formatMoney(price)}/${uomLocal?.toLowerCase()}`,
        VAT: `${vat}%`,
        [DESCRIPTION_BY_LANGUAGE[localLang].preservation]:
          preservation === 'Frozen'
            ? CUSTOM_DESCRIPTION.preservation[localLang][0]
            : preservation === 'Refrigerated'
              ? CUSTOM_DESCRIPTION.preservation[localLang][1]
              : CUSTOM_DESCRIPTION.preservation[localLang][2],
        [DESCRIPTION_BY_LANGUAGE[localLang].status]: inStock
          ? CUSTOM_DESCRIPTION.status[localLang][0]
          : CUSTOM_DESCRIPTION.status[localLang][1],
        [DESCRIPTION_BY_LANGUAGE[localLang].unit]: `(${uomLocal?.toLowerCase()})`,
        [DESCRIPTION_BY_LANGUAGE[localLang].category]: String(category?.nameLocal)
          .replace('&', `${localLang === 'vi' ? 'và' : 'and'}`)
          .toLowerCase(),
      };
      return `${name} ${Object.keys(customDescription).reduce((accumulate, item, index) => {
        return index === Object.keys(customDescription).length - 1
          ? `${accumulate}${item} ${customDescription[item]}`
          : `${accumulate}${item} ${customDescription[item]}, `;
      }, '')}. ${METADATA[`${category?.parent?.name}/${category?.name}`]?.[localLang]?.description}`;
    }
    return '';
  }, [productDetails, localLang]);

  const productMarkup = useMemo(() => {
    let result;
    if (category) {
      result = {
        '@context': 'https://schema.org',
        '@type': 'Product',
        name: `${name ?? ''} | KAMEREO`,
        image: [imageUrl ?? 'https://kamereo.vn/static/images/kamereo_1.jpg'],
        /* ideal length would be 70-155 characters for description */
        description: description && description.length > 70 ? description : customDescriptionValue,
        sku: supplierInternalCode,
        brand: {
          '@type': 'Brand',
          name: 'Kamereo',
        },
        offers: {
          '@type': 'Offer',
          url: renderWithWebDomain(process.env.APP_ENV, `/products/${validProductUrl}`),
          price: price?.toFixed(2) ?? 0.0,
          priceCurrency: 'VND',
          itemCondition: 'https://schema.org/NewCondition',
          availability: 'https://schema.org/InStock',
          seller: {
            '@type': 'Organization',
            name: 'Kamereo',
          },
          shippingDetails: {
            '@type': 'OfferShippingDetails',
            shippingRate: {
              '@type': 'MonetaryAmount',
              value: 0.0,
              currency: 'VND',
            },
            shippingDestination: [
              {
                '@type': 'DefinedRegion',
                addressCountry: 'VN',
                addressRegion: ['Ho Chi Minh City'],
              },
            ],
          },
        },
        // review: {
        //   '@type': 'Review',
        //   reviewRating: {
        //     '@type': 'Rating',
        //     ratingValue: randomRating,
        //     bestRating: 5,
        //   },
        // },
        // aggregateRating: {
        //   '@type': 'AggregateRating',
        //   ratingValue: ((randomRating + 5) / 2).toFixed(1),
        //   reviewCount: getRandomNumber(70, 130),
        // },
      };
    }
    return JSON.stringify(result);
  }, [productDetails, category]);

  const productDetailData = useProductDetailContent(details, name);

  useEffect(() => {
    const readMoreStatus = window.localStorage.getItem('readMoreStatus');
    const readMoreStatusVal = readMoreStatus ? JSON.parse(readMoreStatus) : null;
    if (productDetails?.supplierInternalCode && readMoreStatusVal) {
      const readMoreStatusProductVal = readMoreStatusVal[productDetails.supplierInternalCode];
      setIsShowFullDescription(Boolean(readMoreStatusProductVal));
      if (readMoreStatusProductVal) {
        window.scrollTo({
          top: Number(productDetailRef?.current?.offsetTop) + 100,
        });
      }
    }
    return () => {
      if (productDetails.supplierInternalCode) {
        localStorage.setItem('readMoreStatus', JSON.stringify({ [productDetails.supplierInternalCode]: false }));
      }
    };
  }, [productDetails]);

  return (
    <ProductDetailsContainer>
      <Helmet>
        <title>{`${name || translations(messages.header)} | KAMEREO`}</title>
        <meta property="og:url" content={renderWithWebDomain(process.env.APP_ENV, `/products/${validProductUrl}`)} />
        <link rel="canonical" href={renderWithWebDomain(process.env.APP_ENV, `/products/${validProductUrl}`)} />
        <link rel="alternate" href={renderWithWebDomain(process.env.APP_ENV, `/products/${validProductUrl}`)} />
        <link
          rel="alternate"
          hrefLang={localLang}
          href={renderWithWebDomain(process.env.APP_ENV, `/products/${validProductUrl}`)}
        />
        <link
          rel="alternate"
          hrefLang={alternateLang}
          href={renderWithWebDomain(process.env.APP_ENV, `/products/${alternateProductUrl}`)}
        />
        <link
          rel="alternate"
          hrefLang="x-default"
          href={renderWithWebDomain(
            process.env.APP_ENV,
            `/products/${generateProductSlug(nameLocal?.['vi'], supplierInternalCode)}`,
          )}
        />
        <meta
          name="description"
          content={`${description && description.length > 70 ? description : customDescriptionValue}`}
        />
        <meta
          name="og:description"
          content={`${description && description.length > 70 ? description : customDescriptionValue}`}
        />
        <meta property="og:title" content={`${name || translations(messages.header)} | KAMEREO`} />
        <meta property="og:type" content="product" />
        <meta property="og:site_name" content="Kamereo" />
        <meta property="og:image:url" content={imageUrl || getImageByCategory(category)} />
        <meta property="og:image:secure_url" content={imageUrl || getImageByCategory(category)} />
        <meta property="og:image:alt" content={name || translations(messages.header)} />
        <meta property="og:price:amount" content={String(price || 0)} />
        <meta property="og:price:currency" content={String('VND')} />
        <meta name="robots" content={process.env.APP_ENV === 'production' ? 'index, follow' : 'noindex, nofollow'} />
        <script type="application/ld+json">{breadCrumbDataMarkup}</script>
        <script type="application/ld+json">{productMarkup}</script>
      </Helmet>
      <Product
        ref={productDetailRef}
        added={addedProducts[id]}
        adding={addingProducts[id]}
        product={productDetails}
        permissions={permissions}
        gotoSignIn={gotoSignIn}
        onToggleFavorite={() => onToggleFavorite({ product: productDetails })}
        onOpenNote={() => onClickOpenNoteModal(productDetails)}
        onDeleteNote={() => onClickDeleteNote(productDetails)}
      />
      {loading ? (
        <SkeletonWrapper>
          <Skeleton loading={true} paragraph={{ rows: 8, width: '100%' }} active />
        </SkeletonWrapper>
      ) : (
        productDetailData && (
          <ProductDescriptionWrapper>
            <ProductDescriptionTitle>
              <FormattedMessage {...messages.productDescription} />
            </ProductDescriptionTitle>
            <ProductDescriptionContent
              isShowFullDescription={isShowFullDescription}
              dangerouslySetInnerHTML={{ __html: productDetailData }}
            />
            <ReadMore
              onClick={() => {
                if (isShowFullDescription) {
                  window.scrollTo({
                    top: Number(productDetailRef?.current?.offsetTop) + 100,
                  });
                }
                setIsShowFullDescription(!isShowFullDescription);
                if (productDetails.supplierInternalCode) {
                  localStorage.setItem(
                    'readMoreStatus',
                    JSON.stringify({ [productDetails.supplierInternalCode]: !isShowFullDescription }),
                  );
                }
              }}
              isShowFullDescription={isShowFullDescription}
            >
              {isShowFullDescription ? (
                <FormattedMessage {...messages.collapse} />
              ) : (
                <FormattedMessage {...messages.readMore} />
              )}
            </ReadMore>
          </ProductDescriptionWrapper>
        )
      )}
      {!!relateProducts.length && (
        <Fragment>
          <Header>
            <FormattedMessage {...messages.similarProducts} />
          </Header>
          <Row gutter={[24, 24]}>
            {relateProducts.map((relatedProduct, index) => (
              <Col xs={24} md={6} xxl={4} key={relatedProduct.id}>
                <MarketItem
                  listName="Relate Product List"
                  loggedIn={loggedIn}
                  gotoSignIn={gotoSignIn}
                  product={relatedProduct}
                  loading={relatedProduct.loading}
                  maxQuantity={100}
                  favorite={relatedProduct.isFavorite}
                  note={relatedProduct.note}
                  added={addedProducts[relatedProduct.id]}
                  adding={addingProducts[relatedProduct.id]}
                  position={index}
                  onToggleFavorite={() => onToggleFavorite({ product: relatedProduct })}
                  permissions={permissions}
                  onOpenNote={() => onClickOpenNoteModal(relatedProduct)}
                  onDeleteNote={() => onClickDeleteNote(relatedProduct)}
                  impressionProperties={{ type: TrackingProductImpressionType.RelatedProduct }}
                  addToCartProperties={{ addFrom: TrackingAddToCartAddFromType.RelatedProduct }}
                />
              </Col>
            ))}
          </Row>
        </Fragment>
      )}
      {isOpenNoteModal && (
        <Suspense fallback={<LoadingIndicator />}>
          <ModalNote
            headerLabel={previewProduct?.name || ''}
            isOpen={isOpenNoteModal}
            confirmLoading={noteItemLoading}
            onSubmit={onSubmitAddingNote}
            onClose={onClickCloseNoteModal}
            note={previewProduct?.note || ''}
          />
        </Suspense>
      )}
    </ProductDetailsContainer>
  );
};

const mapStateToProps = createStructuredSelector<RootState, StateProps>({
  productdetailspage: selectProductDetailsPage(),
  store: selectStore(),
  addedProducts: selectAddedProducts(),
  addingProducts: selectAddingProducts(),
  permissions: selectUserPermissions(),
  breadCrumbDataMarkup: selectBreadCrumbDataMarkup(),
});

function mapDispatchToProps(dispatch: Dispatch): DispatchProps {
  return {
    onToggleFavorite: (data) => dispatch(setFavorite(data)),
    setProductNote: (data) => dispatch(setProductNote(data)),
    gotoSignIn: () => dispatch(push('/signin')),
  };
}

const withConnect = connect(mapStateToProps, mapDispatchToProps);

const withReducer = injectReducer<Props>({
  key: 'productDetailsPage',
  reducer: reducer,
});

const withSaga = injectSaga<Props>({
  key: 'productDetailsPage',
  saga: saga,
});

export default compose(withReducer, withSaga, withConnect)(ProductDetailsPage);
