/*
 *
 * ProductDetailsPage actions
 *
 */

import { action, createAsyncAction } from 'typesafe-actions';
import {} from './types';

import ActionTypes from './constants';

export const defaultAction = () => action(ActionTypes.DEFAULT_ACTION);
export const product = createAsyncAction(
  ActionTypes.FETCH_PRODUCT_REQUEST,
  ActionTypes.FETCH_PRODUCT_SUCCESS,
  ActionTypes.FETCH_PRODUCT_FAILURE,
)<void, {}, Error>();
export const relateProducts = createAsyncAction(
  ActionTypes.FETCH_RELATE_PRODUCTS_REQUEST,
  ActionTypes.FETCH_RELATE_PRODUCTS_SUCCESS,
  ActionTypes.FETCH_RELATE_PRODUCTS_FAILURE,
)<void, {}, Error>();
