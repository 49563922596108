import { createMatchSelector, push } from 'connected-react-router';
import { call, fork, put, select } from 'redux-saga/effects';
import { getProduct, getProductBySupplierInternalCode, getProducts } from 'utils/apollo';
import generateProductSlug from 'utils/generateProductSlug';
import isValidUUID from 'utils/isValidUUID';
import parseSlug from 'utils/parseSlug';
import { product as productActions, relateProducts as relateProductsActions } from './actions';
import { LANGUAGES } from 'utils/constants';
import selectProductDetailsPage from './selectors';
import { Product } from 'types/schema';

function generateProductUrl(product: Product, lang: string) {
  return `${generateProductSlug(product.nameLocal?.[lang], product.supplierInternalCode)}${lang === 'vi' ? '' : `?lang=${lang}`}`;
}

function* getProductDetails() {
  try {
    const state = yield select(selectProductDetailsPage());
    const route = yield select(createMatchSelector('/products/:slug'));
    const slug = route.params.slug;
    const localLang = window.localStorage.getItem('lang') || 'vi';
    const loggedIn = !!localStorage.getItem('token');
    const alternateLang = localLang === LANGUAGES.Vi ? LANGUAGES.Alternate : LANGUAGES.Vi;
    let uuid: string = isValidUUID(slug) && slug;
    const isNotPreloadState = loggedIn || !state.product?.id || (state.product?.supplierInternalCode !== parseSlug(slug));
    if (isNotPreloadState) {
      // Set state of product to empty
      state.product = {};
      yield put(productActions.request());
      if (!uuid) {
        const supplierInternalCode = parseSlug(route.params.slug);

        const productBySkuCodeResponse = yield call(getProductBySupplierInternalCode, {
          supplierInternalCode: supplierInternalCode,
        });
        uuid = productBySkuCodeResponse.id;
      }

      // To fetch full information of product
      const response = yield call(getProduct, { id: uuid });
      if (!response.errors) {
        yield handleProductDetailsSuccess(response, localLang, alternateLang);
      } else {
        yield put(push('/not-found'));
      }
    } else {
      const response = state.product;
      yield handleProductDetailsSuccess(response, localLang, alternateLang);
    }
  } catch (error) {
    yield put(productActions.failure(error as Error));
  }
}

function* handleProductDetailsSuccess(response: Product, localLang: string, alternateLang: string) {
  yield put(
    productActions.success({
      ...response,
      loading: false,
      localLang: localLang,
      alternateLang: alternateLang,
      validProductUrl: generateProductUrl(response, localLang),
      alternateProductUrl: generateProductUrl(response, alternateLang),
    }),
  );
  window.localStorage.setItem('lang', localLang as string);
  import('utils/triggerGA4Event').then((module) => {
    const triggerGA4EcommerceEvent = module.default;
    const { EventName } = module;
    triggerGA4EcommerceEvent(EventName.VIEW_ITEM, response);
  }).catch(error => {
    // tslint:disable-next-line: no-console
    console.error('Failed to load GA4 module:', error);
  });
  // Fetch relate products
  yield fork(getRelateProducts, response);
}

function* getRelateProducts(product) {
  yield put(relateProductsActions.request());

  const response = yield call(getProducts, {
    sort: [],
    filter: {
      categoryName: `${product.category.parent.name}/${product.category.name}`,
    },
    pagination: {
      page: 0,
      size: 25,
    },
  });
  if (!response.errors) {
    const data = response.data.filter((item) => item.id !== product.id).slice(0, 24);
    yield put(relateProductsActions.success(data));
  } else {
    yield put(relateProductsActions.failure(response.errors));
  }
}

function* initData() {
  yield call(getProductDetails);
}

// Individual exports for testing
export default function* productDetailsPageSaga() {
  // See example in containers/HomePage/saga.js
  yield fork(initData);
}
