import generateResponsiveImage from './generateResponsiveImage';
import getHeightFromAspectRatio from './urls/getHeightFromAspectRatio';

const getResponsiveSrc = (src: string, responsiveImageSizes: number[], withRatio?: string) => {
  const generateSrcset = (size: number) => {
    const height = withRatio ? getHeightFromAspectRatio(size, withRatio) : size;
    return `${generateResponsiveImage(src, size, height)} ${size}w`;
  };

  return responsiveImageSizes.map(generateSrcset).join(', ');
};

export default getResponsiveSrc;
